.accordian-main {
  cursor: pointer;
  display: inline-block;
  -webkit-text-decoration: none;
  text-decoration: none;
  font-size: 14px;
  /*background-color: rebeccapurple;*/
  /*background-image: linear-gradient(45deg, #1f4d1f 0%, #b8b4b4 100%);*/
  background-image: linear-gradient(
          to right,
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 0.75),
          rgba(0, 0, 0, 0)
  );
  border: none;
  border-radius: 4px;
  padding: 10px;
  color: #fff;
}

.markdown {
  /*color: rebeccapurple;*/
  /*color: #101018f8;*/
  color: rgba(0,0,0,.75);
  font-weight: 700;
  font-size: 1.3rem;
}

.list-styles {
  list-style-type: circle;
}

.image-style1 {
  width: 3em;
  height: 3em;
}

@media screen and (max-width: 680px) {
  /* * {
    padding: 0;
    margin: 0;
  } */

  /* .container-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0;
    margin: 0;
  } */
}
